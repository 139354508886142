/**
 *
 * @param {null|string} message
 */
const showLoader = (message = null) => {
    document.querySelector('body').classList.add('is-loading');
    const loaderTxt = document.querySelector('.js-page-loader-txt');
    if(!loaderTxt) return;
    //loaderTxt.innerHTML = message ?? 'Processing your request';
    loaderTxt.innerHTML = message ?? 'तपाईंको अनुरोध प्रशोधन गर्दै';
}
const removeLoader = () => document.querySelector('body').classList.remove('is-loading');
const pageLoader = {
    show: showLoader,
    hide: removeLoader,
}
export {pageLoader};
