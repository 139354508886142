import {pageLoader} from "./_page_loader";
import {printProgram} from "./_print";

/**
 *
 * @param {{
 * url: string,
 * method?: "GET" | "POST",
 * headers?: Record<string, string>,
 * body?: any
 * }} requestConfig
 * @param {Function|null} applyDataTo
 * @returns {Promise<void>}
 */
const fetchData = async (requestConfig, applyDataTo) => {
    try{
        const response = await fetch(requestConfig.url, {
            method: requestConfig.method ?? "GET",
            headers: requestConfig.headers ?? {},
            body: requestConfig.body
        })

        //console.log("start api call", requestConfig);
        if(!response.ok){
            const errorData = await response.json();
            //errorData.detail
            const errorMessage = errorData.message || "Request failed";
            throw { message: errorMessage, responseData: errorData }; // Include responseData in the error object
        }

        const responseData = await response.json();
        if(applyDataTo) applyDataTo(responseData);
    }catch (err) {
        // Return the error along with the response data
        throw { message: err.message || "something went wrong", responseData: err.responseData };
    }
}

const jsonRequestHeader = {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest"
}



export {jsonRequestHeader, fetchData, pageLoader, printProgram};
