const printProgram = (url) => {
    const printWindowHandle = window.open(
        url,
        "_blank",
        "width=900,height=620"
    );
    if(!printWindowHandle) return;
    printWindowHandle.addEventListener('load', evt => {
        printWindowHandle?.print();
    })
    printWindowHandle.addEventListener('afterprint', evt => {
        printWindowHandle?.close();
    })
}

export {printProgram};